import React from "react";
import CaseList from "../components/CaseList";

function List() {
	return (
		<div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 my-10">
			<CaseList />
		</div>
	);
}

export default List;
