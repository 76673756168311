import React, { useEffect, useRef, useState } from "react";
import * as cornerstone from "cornerstone-core";
import cornerstoneTools, { ZoomTool, LengthTool } from "cornerstone-tools";
import Hammer from "hammerjs/hammer.js";
import cornerstoneMath from "cornerstone-math";
import cornerstoneWebImageLoader from "cornerstone-web-image-loader";
import * as cornerstoneWADOImageLoader from "cornerstone-wado-image-loader";
import * as dicomParser from "dicom-parser/dist/dicomParser";
import { useSearchParams } from "react-router-dom";

function DicomViewer() {
	const [loadTool, setLoadTool] = useState(null);
	const canvasRef = useRef(null);
	const element = canvasRef.current;

	const [searchParams] = useSearchParams();
	const query = searchParams.get("url");

	useEffect(() => {
		cornerstoneWADOImageLoader.external.cornerstone = cornerstone;
		cornerstoneWADOImageLoader.external.dicomParser = dicomParser;
		cornerstoneTools.external.cornerstone = cornerstone;
		cornerstoneTools.external.cornerstoneMath = cornerstoneMath;
		cornerstoneTools.external.Hammer = Hammer;

		const element = canvasRef.current;

		cornerstone.loadAndCacheImage("wadouri:" + query).then((image) => {
			console.log("image", image);
			cornerstone.enable(element);
			var viewport = cornerstone.getDefaultViewportForImage(element, image);
			element.style.width = viewport.windowWidth;
			element.style.height = "500px";
			cornerstone.displayImage(element, image, viewport);
			setLoadTool(true); // enable
		});
	}, []);

	useEffect(() => {
		const element = canvasRef.current;

		cornerstoneTools.init();
		cornerstoneTools.addToolForElement(element, ZoomTool);
		cornerstoneTools.addToolForElement(element, LengthTool);
		cornerstoneTools.setToolActive("Zoom", {
			mouseButtonMask: 2,
			minScale: 0.25,
			maxScale: 20.0,
			preventZoomOutsideImage: true,
		}); // activate ZoomTool with left mouse button

		cornerstoneTools.setToolActive("Length", {
			mouseButtonMask: 1,
		});
	}, [loadTool]);

	const enableTool = (cornerStoneTool, mouseButtonMask, tool) => {
		cornerstoneTools.init();
		cornerstoneTools.addTool(cornerStoneTool, {
			configuration: {
				invert: false,
				preventZoomOutsideImage: false,
				minScale: 0.1,
				maxScale: 20.0,
			},
		});

		cornerstoneTools.setToolActive(tool, { mouseButtonMask: 1 });
	};

	const disableAllTools = () => {
		cornerstoneTools.wwwc.disable(canvasRef);
		// cornerstoneTools.pan.activate(canvasRef, 2); // 2 is middle mouse button
		// cornerstoneTools.zoom.activate(canvasRef, 4); // 4 is right mouse button
		// cornerstoneTools.probe.deactivate(canvasRef, 1);
		// cornerstoneTools.length.deactivate(canvasRef, 1);
		// cornerstoneTools.ellipticalRoi.deactivate(canvasRef, 1);
		// cornerstoneTools.rectangleRoi.deactivate(canvasRef, 1);
		// cornerstoneTools.angle.deactivate(canvasRef, 1);
		// cornerstoneTools.highlight.deactivate(canvasRef, 1);
		// cornerstoneTools.freehand.deactivate(canvasRef, 1);
	};

	return (
		<>
			<div className="viewer" ref={canvasRef}></div>
			<button
				onClick={() => {
					enableTool(cornerstoneTools.ZoomTool, 5, "Zoom");
				}}
				className="mx-2 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
				{" "}
				Zoom
			</button>
			<button
				onClick={() => {
					enableTool(cornerstoneTools.WwwcTool, 1, "Wwwc");
				}}
				className="mx-2 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
				{" "}
				Wwwc
			</button>
			<button
				onClick={() => {
					enableTool(
						cornerstoneTools.EllipticalRoiTool,
						6,
						"EllipticalRoiTool"
					);
				}}
				className="mx-2 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
				{" "}
				EllipticalRoiTool
			</button>
			<button
				onClick={() => {
					enableTool(cornerstoneTools.RectangleRoiTool, 1, "RectangleRoiTool");
				}}
				className="mx-2 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
				{" "}
				RectangleRoiTool
			</button>
		</>
	);
}

export default DicomViewer;
