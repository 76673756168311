import React from "react";
import CaseForm from "../components/CaseForm";

function Case() {
	return (
		<div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 my-10">
			<CaseForm />
		</div>
	);
}

export default Case;
