import { Formik } from "formik";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export default function LoginForm() {
	const [registerType, setRegisterType] = useState("doctor");
	const [error, setError] = useState("");
	const [loading, setloading] = useState(false);
	const navigate = useNavigate();

	const formHandle = async (values, url) => {
		setloading(true);
		setError("");
		const requestOptions = {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify({
				email: values.email,
				password: values.password,
			}),
		};

		fetch(`${url}`, requestOptions)
			.then((response) => {
				setloading(false);
				if (response.status == 200) {
					return response.json();
				} else {
					throw "Invalid User";
				}
			})
			.then((data) => {
				console.log("data", data);
				localStorage.setItem("token", data.accessToken);
				window.location.reload();
			})
			.catch((err) => {
				setError(err);
				console.log("err", err);
			});
	};

	return (
		<>
			<div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
				<div className="sm:mx-auto sm:w-full sm:max-w-md">
					<div className="flex justify-around">
						<span className="isolate inline-flex rounded-md shadow-sm">
							<button
								type="button"
								onClick={() => {
									setRegisterType("doctor");
									setError("");
								}}
								className={`${
									registerType == "doctor" && "bg-indigo-500 text-white"
								} relative inline-flex items-center rounded-l-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 focus:z-10`}>
								Login as Doctor
							</button>
							<button
								type="button"
								onClick={() => {
									setRegisterType("patient");
									setError("");
								}}
								className={`${
									registerType == "patient" && "bg-indigo-500 text-white"
								} relative -ml-px inline-flex items-center rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 focus:z-10`}>
								Login as Patient
							</button>
						</span>
					</div>
				</div>
				<div className="sm:mx-auto sm:w-full sm:max-w-sm">
					<Formik
						initialValues={{ email: "", password: "" }}
						validate={(values) => {
							const errors = {};
							if (!values.email || !values.password) {
								errors.email = "* Required";
								errors.password = "* Required";
							} else if (
								!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
							) {
								errors.email = "Invalid email address";
							}
							return errors;
						}}
						onSubmit={async (
							values,
							{ setErrors, setStatus, setSubmitting }
						) => {
							if (registerType === "doctor") {
								formHandle(
									values,
									"https://testapi.secondop.in/backend/api/v1/auth/login"
								);
							} else {
								formHandle(
									values,
									"https://testapi.secondop.in/backend/api/v1/auth/login/patient"
								);
							}
						}}>
						{({
							values,
							errors,
							touched,
							handleChange,
							handleBlur,
							handleSubmit,
							isSubmitting,
							/* and other goodies */
						}) => (
							<div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
								<form onSubmit={handleSubmit} className="space-y-6">
									{error && (
										<span className="text-rose-700 text-sm">{error}</span>
									)}
									<div>
										<label
											htmlFor="email"
											className="block text-sm font-medium leading-6 text-gray-900">
											Email address
										</label>
										<input
											type="email"
											name="email"
											onChange={handleChange}
											onBlur={handleBlur}
											value={values.email}
											className="px-2 mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
										/>
										<span className="text-rose-800 text-xs">
											{errors.email && touched.email && errors.email}
										</span>
									</div>
									<div>
										<label
											htmlFor="password"
											className="block text-sm font-medium leading-6 text-gray-900">
											Password
										</label>
										<input
											type="password"
											name="password"
											onChange={handleChange}
											onBlur={handleBlur}
											value={values.password}
											className="px-2 mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
										/>
										<span className="text-rose-800 text-xs">
											{errors.password && touched.password && errors.password}
										</span>
									</div>
									<button
										type="submit"
										className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
										disabled={isSubmitting}>
										{loading ? "loading.." : "Submit"}
									</button>
								</form>
							</div>
						)}
					</Formik>
				</div>
			</div>
		</>
	);
}
