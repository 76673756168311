import moment from "moment";
import { useEffect, useState } from "react";
import { useParams, Link, Navigate, useNavigate } from "react-router-dom";
import SuccessNotifcation from "../notifications/success";
import Accordion from "../components/Accordion";
import ImageModal from "../modals/ImageModal";

function Doctor() {
	let fileCheck = new RegExp(
		/[^\s]+(.*?).(jpg|jpeg|png|gif|JPG|JPEG|PNG|GIF)$/
	);

	const [data, setData] = useState([]);
	const [questionData, setQuestionData] = useState([]);
	const [localData, setLocalData] = useState([]);
	const [loading, setLoading] = useState(true);
	const [showNotifcation, setShowNotifcation] = useState(false);
	const [comment, setComment] = useState("");
	const [url, setUrl] = useState("");
	const [showImageModal, setShowImageModal] = useState(false);
	const navigate = useNavigate();
	const { id } = useParams();

	useEffect(() => {
		fetchCase();
	}, []);

	const fetchCase = async () => {
		console.log("fetching case");
		console.log("case id", id);
		setLoading(true);
		await fetch(`https://testapi.secondop.in/backend/api/v1/data/case/${id}`)
			.then((response) => response.json())
			.then((data) => {
				console.log("case data", data);
				setData(data);
				setLoading(false);
			});
	};

	const handleSubmit = (question_id) => {
		console.log(question_id, id, comment);
		const requestOptions = {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify({
				question_id,
				case_id: id,
				description: comment,
			}),
		};
		fetch(
			"https://testapi.secondop.in/backend/api/v1/data/opinion",
			requestOptions
		)
			.then((response) => response.json())
			.then((data) => {
				setComment("");
				console.log("data", data);
				setShowNotifcation(true);
				setTimeout(() => {
					setShowNotifcation(false);
					window.location.reload();
				}, 2000);
			});
	};

	const handleOpinion = (e, opinion) => {
		console.log(e.target.value, opinion);
		setComment(e.target.value);
		setQuestionData((prevValues) => [
			...prevValues,
			{ questionId: opinion.question_id, comment: e.target.value },
		]);
	};

	// useEffect(() => {
	// 	const checkLocal = localStorage.getItem("questionData");
	// 	console.log("Data", checkLocal);
	// 	setLocalData(checkLocal);
	// }, []);

	const handleCaseClosed = (e) => {
		e.preventDefault();

		console.log("case_id", id);
		console.log("data", data);
		console.log("user_id", data);

		const requestOptions = {
			method: "PUT",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify({
				case_id: id,
				data: JSON.stringify(data),
				user_id: data.personal_information,
			}),
		};
		fetch(
			"https://testapi.secondop.in/backend/api/v1/data/case",
			requestOptions
		)
			.then((response) => response.json())
			.then((data) => {
				setComment("");
				// console.log("data", data);
				setShowNotifcation(true);
				setTimeout(() => {
					setShowNotifcation(false);
					// window.location.reload();
				}, 2000);
			});
	};

	const handleViewImage = async (url) => {
		await fetch(
			`https://testapi.secondop.in/backend/api/v1/signed/document/${url}`
		)
			.then((response) => response.json())
			.then((data) => {
				setUrl(data.url);
				window.open(
					`/dicom?url=${encodeURIComponent(data.url)}`,
					"_blank",
					"noreferrer"
				);
			});
	};

	const handleViewDicom = async (url) => {
		await fetch(
			`https://testapi.secondop.in/backend/api/v1/signed/document/${url}`
		)
			.then((response) => response.json())
			.then((data) => {
				setUrl(data.url);
				window.open(
					`/dicom-two?url=${encodeURIComponent(data.url)}`,
					"_blank",
					"noreferrer"
				);
			});
	};

	if (loading) return <p>Fetching user details...</p>;

	return (
		<>
			<SuccessNotifcation
				show={showNotifcation}
				setShowNotifcation={() => setShowNotifcation(false)}
			/>

			<ImageModal
				showImageModal={showImageModal}
				handleModal={() => {
					setShowImageModal(false);
					setUrl("");
				}}
				url={url}
			/>
			<div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 my-10">
				<>
					<h2 className="text-2xl mb-10 font-bold leading-7 my-10 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
						Patient Information
					</h2>
					{!loading && (
						<form>
							<div className="space-y-12">
								<div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
									<div>
										<h2 className="text-base font-semibold leading-7 text-gray-900">
											Personal Information
										</h2>
										{/* <p className="mt-1 text-sm leading-6 text-gray-600">
											Use a permanent address where you can receive mail.
										</p> */}
									</div>

									<div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
										<div className="sm:col-span-3">
											<label
												htmlFor="first-name"
												className="block text-sm font-medium leading-6 text-gray-900">
												First name
											</label>
											<div className="mt-2">
												<input
													type="text"
													name="first-name"
													id="first-name"
													autoComplete="given-name"
													value={data.personal_information.firstname}
													className="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
												/>
											</div>
										</div>

										<div className="sm:col-span-3">
											<label
												htmlFor="last-name"
												className="block text-sm font-medium leading-6 text-gray-900">
												Last name
											</label>
											<div className="mt-2">
												<input
													type="text"
													name="last-name"
													id="last-name"
													autoComplete="family-name"
													value={data.personal_information.lastname}
													className="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
												/>
											</div>
										</div>

										<div className="sm:col-span-3">
											<label
												htmlFor="gender"
												className="block text-sm font-medium leading-6 text-gray-900">
												Gender
											</label>
											<div className="mt-2">
												<input
													id="phone"
													name="phone"
													type="text"
													value={data.personal_information.gender}
													className="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
												/>
											</div>
										</div>

										<div className="sm:col-span-3">
											<label
												htmlFor="phone"
												className="block text-sm font-medium leading-6 text-gray-900">
												Date of Birth
											</label>
											<div className="mt-2">
												<input
													id="phone"
													name="phone"
													type="text"
													value={moment().diff(
														data.personal_information.dob,
														"years",
														false
													)}
													className="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
												/>
											</div>
										</div>
									</div>
								</div>

								<div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
									<div>
										<h2 className="text-base font-semibold leading-7 text-gray-900">
											Patient Documents
										</h2>
										{/* <p className="mt-1 text-sm leading-6 text-gray-600">
											We'll always let you know about important changes, but you
											pick what else you want to hear about.
										</p> */}
									</div>

									<div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6 md:col-span-2">
										{/* Table */}

										<div className="sm:col-span-6 flow-root w-100">
											<div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
												<div className="inline-block min-w-full pb-2 align-middle sm:px-6 lg:px-8">
													<table className="min-w-full divide-y divide-gray-300">
														<thead>
															<tr>
																<th
																	scope="col"
																	className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
																	Document Name
																</th>
																<th
																	scope="col"
																	className="relative py-3.5 pl-3 pr-4 sm:pr-0">
																	<span className="sr-only">Remove</span>
																</th>
															</tr>
														</thead>

														<tbody className="divide-y divide-gray-200">
															{data.reports &&
																data.reports.map((reports) => (
																	<tr key={reports.id}>
																		<td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
																			{reports.name}
																		</td>
																		<td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
																			{fileCheck.test(reports.location) ===
																			true ? (
																				<button
																					className="mx-2 text-indigo-600 hover:text-indigo-900"
																					type="button"
																					onClick={() =>
																						handleViewImage(
																							reports.location.split(".com/")[1]
																						)
																					}>
																					View Document
																				</button>
																			) : (
																				<button
																					className="text-indigo-600 hover:text-indigo-900"
																					type="button"
																					onClick={() =>
																						handleViewDicom(
																							reports.location.split(".com/")[1]
																						)
																					}>
																					View Document
																				</button>
																			)}
																		</td>
																	</tr>
																))}
														</tbody>
													</table>
												</div>
											</div>
										</div>
									</div>
								</div>

								<div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
									<div>
										<h2 className="text-base font-semibold leading-7 text-gray-900">
											Questions
										</h2>
										{/* <p className="mt-1 text-sm leading-6 text-gray-600">
											Leave your questions for doctors (max length 250)
										</p> */}
									</div>

									<div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6 md:col-span-2">
										<div className="sm:col-span-6 flow-root w-100">
											<div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
												<div className="inline-block min-w-full pb-2 align-middle sm:px-6 lg:px-8">
													{data.opinions && (
														<Accordion
															opinions={data.opinions}
															questions={data.questions}
															handleOpinion={handleOpinion}
															handleSubmit={handleSubmit}
														/>
													)}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="mt-6 flex items-center justify-end gap-x-6">
								{data.status === "active" && (
									<button
										onClick={(e) => handleCaseClosed(e)}
										disabled={loading}
										type={!loading && "submit"}
										className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
										{loading ? "Loading..." : "Close Case"}
									</button>
								)}
							</div>
						</form>
					)}
				</>
			</div>
		</>
	);
}

export default Doctor;
