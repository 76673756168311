import { ChevronRightIcon } from "@heroicons/react/20/solid";
import { useEffect, useState } from "react";
import moment from "moment";

export default function CaseList() {
	const [cases, setCases] = useState([]);

	useEffect(() => {
		fetchCases();
	}, []);

	const fetchCases = async () => {
		await fetch("https://testapi.secondop.in/backend/api/v1/data/cases")
			.then((response) => response.json())
			.then((data) => setCases(data));
	};

	return (
		<ul role="list" className="divide-y divide-gray-100">
			{cases.map((item) => (
				<li key={item.id} className="relative py-5 hover:bg-gray-50">
					<a href={`/doctor/${item.id}`}>
						<div className="px-4 sm:px-6 lg:px-8">
							<div className="mx-auto flex max-w-4xl justify-between gap-x-6">
								<div className="flex min-w-0 gap-x-4">
									<div className="min-w-0 flex-auto">
										<p className="text-sm font-semibold leading-6 text-gray-900">
											<p>
												<span className="absolute inset-x-0 -top-px bottom-0" />
												Case ID: {item.id}
											</p>
										</p>
										{/* <p className="mt-1 flex text-xs leading-5 text-gray-500">
											<a className="relative truncate hover:underline">
												Diagnosis: {"Cardio"}
											</a>
										</p> */}
									</div>
								</div>
								<div className="flex shrink-0 items-center gap-x-4">
									<div className="hidden sm:flex sm:flex-col sm:items-end">
										<p className="capitalize text-sm leading-6 text-gray-900">
											<strong>Date Created: </strong>
											{moment(item.created_on).format("MMM Do YY")}
											{
												<div className="mt-1 flex items-center gap-x-1.5">
													<div className="flex-none rounded-full bg-emerald-500/20 p-1">
														{item.status === "active" ? (
															<div className="h-1.5 w-1.5 rounded-full bg-emerald-500" />
														) : (
															<div className="h-1.5 w-1.5 rounded-full bg-orange-500" />
														)}
													</div>
													<p className="text-xs leading-5 text-gray-500">
														{item.status}
													</p>
												</div>
											}
										</p>
									</div>
									<ChevronRightIcon
										className="h-5 w-5 flex-none text-gray-400"
										aria-hidden="true"
									/>
								</div>
							</div>
						</div>
					</a>
				</li>
			))}
		</ul>
	);
}
