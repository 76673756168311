import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./layouts/Layout.js";
import Home from "./pages/Home.js";
import NoPage from "./pages/NoPage.js";
import Case from "./pages/Case.js";
import Doctor from "./pages/Doctor.js";
import List from "./pages/List.js";
import Login from "./pages/Login.js";
import AuthRoutes from "./routes/AuthRoutes.js";
import Register from "./pages/Register.js";
import DicomViewer from "./pages/DicomViewer.js";
import DicomViewerTwo from "./pages/DicomViewerTwo.js";
import NonRoutes from "./routes/NonAuthRoutes.js";

function App() {
	return (
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<Layout />}>
					<Route element={<AuthRoutes />}>
						<Route path="/doctor/:id" element={<Doctor />} />
						<Route path="/list" element={<List />} />
						<Route path="/dicom" element={<DicomViewer />} />
						<Route path="/dicom-two" element={<DicomViewerTwo />} />
						<Route path="/case" element={<Case />} />
					</Route>
					<Route index element={<Home />} />
					{/* <Route path="/dicom-zoom" element={<DicomViewerZoom />} /> */}
					<Route element={<NonRoutes />}>
						<Route path="/login" element={<Login />} />
						<Route path="/register" element={<Register />} />
					</Route>
					<Route path="*" element={<NoPage />} />
				</Route>
			</Routes>
		</BrowserRouter>
	);
}

export default App;
