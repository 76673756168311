import { Outlet, Link } from "react-router-dom";
import NavBar from "../components/NavBar";

const Layout = ({ children }) => {
	let auth = { token: localStorage.getItem("token") };
	return (
		<>
			<NavBar isAuthenticated={auth.token ? true : false} />
			<Outlet />
		</>
	);
};

export default Layout;
