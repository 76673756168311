import Price from "./Price";
import { useEffect, useState, useRef } from "react";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import SuccessNotifcation from "../notifications/success";
import { useNavigate } from "react-router-dom";

export default function CaseForm() {
	const navigate = useNavigate();

	const inputRef = useRef(null);
	const [firstname, setFirstName] = useState("");
	const [lastname, setLastname] = useState("");
	const [email, setEmail] = useState("");
	const [phone, setPhone] = useState("");
	const [gender, setGender] = useState("");
	const [dob, setDob] = useState("");
	const [city, setCity] = useState("");
	const [country, setCountry] = useState("");
	const [packageName, setPackageName] = useState(2);
	const [comment, setComment] = useState("");
	const [comments, setComments] = useState([]);
	const [documentTitle, setDocumentTitle] = useState("");
	const [documents, setDocuments] = useState([]);
	const [tempCaseId, setTempCaseId] = useState("");
	const [selfCreation, setSelfCreation] = useState(true);
	const [user, setUser] = useState([]);

	useEffect(() => {
		const id = uuidv4();
		setTempCaseId(id);
	}, []);

	useEffect(() => {
		if (selfCreation === true) {
			setFirstName(user.firstname);
			setLastname(user.lastname);
			setEmail(user.email);
			setPhone(user.phone_number);
			setGender(user.gender);
			setDob(user.dob);
			setCity(user.city);
			setCountry(user.country);
		} else {
			setFirstName("");
			setLastname("");
			setEmail("");
			setPhone("");
			setGender("");
			setDob("");
			setCity("");
			setCountry("");
		}
	}, [selfCreation]);

	useEffect(() => {
		getUserInformation();
	}, []);

	const getUserInformation = async () => {
		const token = await localStorage.getItem("token");
		await fetch(`https://testapi.secondop.in/backend/api/v1/data/user`, {
			method: "GET",
			headers: {
				authorization: `Bearer ${token}`,
			},
		})
			.then((response) => response.json())
			.then((data) => {
				setUser(data[0]);
				setFirstName(data[0].firstname);
				setLastname(data[0].lastname);
				setEmail(data[0].email);
				setPhone(data[0].phone_number);
				setGender(data[0].gender);
				setDob(data[0].dob);
				setCity(data[0].city);
				setCountry(data[0].country);
			})
			.catch((err) => {
				localStorage.removeItem("token");
				navigate("/login");
			});
	};

	const [showNotifcation, setShowNotifcation] = useState(false);
	const [loading, setLoading] = useState(false);
	//drag events
	const [isDragActive, setIsDragActive] = useState(false);
	const [file, setFile] = useState("");

	// New requirements
	const [progress, setProgress] = useState(0);

	const handleComments = () => {
		const id = uuidv4();
		setComment("");
		setComments((oldComments) => [
			...oldComments,
			{ id, description: comment },
		]);
		localStorage.setItem(
			"comments",
			JSON.stringify([...comments, { id, description: comment }])
		);
	};

	// get comments from localstorage
	useEffect(() => {
		const items = JSON.parse(localStorage.getItem("comments"));
		if (items) {
			setComments(items);
		}
	}, []);

	// get documents from localstorage
	useEffect(() => {
		const items = JSON.parse(localStorage.getItem("documents"));
		if (items) {
			setDocuments(items);
		}
	}, []);

	const handleRemoveComments = (id) => {
		const filteredComments = comments.filter((comment) => comment.id !== id);
		setComments(filteredComments);
		localStorage.setItem("comments", JSON.stringify(filteredComments));
	};

	const handleSubmit = (e) => {
		setLoading(true);
		e.preventDefault();

		const requestOptions = {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify({
				personal_information: {
					id: selfCreation ? user.id : tempCaseId,
					firstname,
					lastname,
					email,
					phone,
					city,
					country,
					gender,
					dob,
				},
				documents,
				comments,
				packageName: 1,
				selfCreation,
			}),
		};
		fetch(
			"https://testapi.secondop.in/backend/api/v1/data/case",
			requestOptions
		)
			.then((response) => response.json())
			.then((data) => {
				setLoading(false);
				setShowNotifcation(true);
				localStorage.setItem("comments", JSON.stringify([]));
				localStorage.setItem("documents", JSON.stringify([]));
				setTimeout(() => {
					setShowNotifcation(false);
					window.location.reload();
				}, 2000);
			});
	};

	const handleDocumentUpload = (uploads) => {
		if (documents.length > 9) {
			alert("cannot upload more than 10");
			return;
		}
		setLoading(true);
		const url = "https://testapi.secondop.in/backend/api/v1/upload-single";
		for (const file of uploads) {
			const formData = new FormData();
			formData.append("folder", tempCaseId);
			formData.append("name", file.name);
			formData.append("file", file);
			const config = {
				headers: {
					"content-type": "multipart/form-data",
				},
				onUploadProgress: (data) => {
					setLoading(true);
					setProgress(Math.round((100 * data.loaded) / data.total));
				},
			};
			axios.post(url, formData, config).then((response) => {
				handleDocuments(response.data);
				setProgress(0);
				setLoading(false);
			});
		}
	};

	const handleDocuments = (data) => {
		setFile("");
		setDocumentTitle("");
		setDocuments((oldDocuments) => [...oldDocuments, data]);
		localStorage.setItem("documents", JSON.stringify([...documents, data]));
	};

	const handleDocumentDelete = async (e, id) => {
		e.preventDefault();
		await fetch(`https://testapi.secondop.in/backend/api/v1/delete/${id}`, {
			method: "delete",
		})
			.then((response) => response.json())
			.then((data) => console.log("data", data));
	};

	const handleRemoveDocuments = (id) => {
		const filteredDocuments = documents.filter(
			(document) => document.key !== id
		);
		setDocuments(filteredDocuments);
		localStorage.setItem("documents", JSON.stringify(filteredDocuments));
	};

	const handleDragEnter = () => {
		setIsDragActive(true);
	};

	const handleDragLeave = () => {
		setIsDragActive(false);
	};

	const openFileExplorer = () => {
		inputRef.current.value = "";
		inputRef.current.click();
	};

	const handleDrop = (e) => {
		e.preventDefault();
		setIsDragActive(false);
		const files = Array.from(e.dataTransfer.files);
		if (files > 9) {
			alert("Cannot add more than 10 files");
			return;
		}
		setIsDragActive(false);
		handleDocumentUpload(e.dataTransfer.files);
	};

	const handleSelectUpload = (e) => {
		e.preventDefault();
		setIsDragActive(false);
		if (e.target.files > 9) {
			alert("Cannot add more than 10 files");
			return;
		}
		setIsDragActive(false);
		handleDocumentUpload(e.target.files);
	};

	const updateDocument = (e, p) => {
		const updatedDocuments = [...documents];
		const document = updatedDocuments.find((a) => a.key === p.key);
		document.name = e.target.value;
		setDocuments(updatedDocuments);
	};

	return (
		<>
			<SuccessNotifcation
				show={showNotifcation}
				setShowNotifcation={() => setShowNotifcation(false)}
			/>
			<form onSubmit={handleSubmit}>
				<div className="space-y-12">
					<div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
						<div>
							<h2 className="text-base font-semibold leading-7 text-gray-900">
								Case Creation
							</h2>
							<p className="mt-1 text-sm leading-6 text-gray-600">
								Are you creating case for your self?
							</p>
						</div>

						<div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
							<fieldset>
								<div className="mt-6 space-y-3">
									<div className="flex items-center gap-x-3">
										<input
											id="self-creation"
											name="self-creation"
											type="radio"
											value={selfCreation}
											onChange={(e) => setSelfCreation(true)}
											defaultChecked
											className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
										/>
										<label
											htmlFor="self-creation"
											className="block text-sm font-medium leading-6 text-gray-900">
											Yes
										</label>
									</div>
									<div className="flex items-center gap-x-3">
										<input
											id="self-creation"
											name="self-creation"
											type="radio"
											value={selfCreation}
											onChange={(e) => setSelfCreation(false)}
											className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
										/>
										<label
											htmlFor="self-creation"
											className="block text-sm font-medium leading-6 text-gray-900">
											No
										</label>
									</div>
								</div>
							</fieldset>
						</div>
					</div>
					<div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
						<div>
							<h2 className="text-base font-semibold leading-7 text-gray-900">
								Personal Information
							</h2>
							<p className="mt-1 text-sm leading-6 text-gray-600">
								Use a permanent address where you can receive mail.
							</p>
						</div>

						<div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
							<div className="sm:col-span-3">
								<label
									htmlFor="first-name"
									className="block text-sm font-medium leading-6 text-gray-900">
									First name
								</label>
								<div className="mt-2">
									<input
										type="text"
										name="first-name"
										id="first-name"
										autoComplete="given-name"
										value={firstname}
										onChange={(e) => setFirstName(e.target.value)}
										disabled={selfCreation}
										className={
											selfCreation
												? "bg-gray-50 px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
												: "px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
										}
									/>
								</div>
							</div>

							<div className="sm:col-span-3">
								<label
									htmlFor="last-name"
									className="block text-sm font-medium leading-6 text-gray-900">
									Last name
								</label>
								<div className="mt-2">
									<input
										type="text"
										name="last-name"
										id="last-name"
										autoComplete="family-name"
										value={lastname}
										onChange={(e) => setLastname(e.target.value)}
										disabled={selfCreation}
										className={
											selfCreation
												? "bg-gray-50 px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
												: "px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
										}
									/>
								</div>
							</div>

							<div className="sm:col-span-4">
								<label
									htmlFor="email"
									className="block text-sm font-medium leading-6 text-gray-900">
									Email address
								</label>
								<div className="mt-2">
									<input
										id="email"
										name="email"
										type="email"
										autoComplete="email"
										value={email}
										onChange={(e) => setEmail(e.target.value)}
										disabled={selfCreation}
										className={
											selfCreation
												? "bg-gray-50 px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
												: "px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
										}
									/>
								</div>
							</div>

							<div className="sm:col-span-4">
								<label
									htmlFor="phone"
									className="block text-sm font-medium leading-6 text-gray-900">
									Phone
								</label>
								<div className="mt-2">
									<input
										id="phone"
										name="phone"
										type="text"
										autoComplete="phone"
										value={phone}
										onChange={(e) => setPhone(e.target.value)}
										disabled={selfCreation}
										className={
											selfCreation
												? "bg-gray-50 px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
												: "px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
										}
									/>
								</div>
							</div>

							<div className="sm:col-span-3">
								<label
									htmlFor="gender"
									className="block text-sm font-medium leading-6 text-gray-900">
									Gender
								</label>
								<div className="mt-2">
									<select
										value={gender}
										onChange={(e) => setGender(e.target.value)}
										id="gender"
										name="gender"
										autoComplete="gender"
										disabled={selfCreation}
										className={
											selfCreation
												? "bg-gray-50 px-2 block w-full rounded-md border-0 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
												: "px-2 bg-white block w-full rounded-md border-0 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
										}>
										<option selected>Select</option>
										<option>Male</option>
										<option>Female</option>
									</select>
								</div>
							</div>

							<div className="sm:col-span-3">
								<label
									htmlFor="phone"
									className="block text-sm font-medium leading-6 text-gray-900">
									Date of Birth
								</label>
								<div className="mt-2">
									<input
										id="phone"
										name="phone"
										type="date"
										autoComplete="phone"
										value={dob}
										onChange={(e) => setDob(e.target.value)}
										disabled={selfCreation}
										className={
											selfCreation
												? "bg-gray-50 px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
												: "px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
										}
									/>
								</div>
							</div>

							<div className="sm:col-span-3">
								<label
									htmlFor="country"
									className="block text-sm font-medium leading-6 text-gray-900">
									Country
								</label>
								<div className="mt-2">
									<select
										value={country}
										onChange={(e) => setCountry(e.target.value)}
										id="gender"
										name="gender"
										autoComplete="gender"
										disabled={selfCreation}
										className={
											selfCreation
												? "bg-gray-50 px-2 block w-full rounded-md border-0 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
												: "px-2 block bg-white w-full rounded-md border-0 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
										}>
										<option selected>Select</option>
										<option>United States</option>
										<option>Canada</option>
										<option>Mexico</option>
									</select>
								</div>
							</div>

							<div className="sm:col-span-3">
								<label
									htmlFor="city"
									className="block text-sm font-medium leading-6 text-gray-900">
									City
								</label>
								<div className="mt-2">
									<input
										type="text"
										name="city"
										id="city"
										value={city}
										onChange={(e) => setCity(e.target.value)}
										autoComplete="address-level2"
										disabled={selfCreation}
										className={
											selfCreation
												? "bg-gray-50 px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
												: "px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
										}
									/>
								</div>
							</div>
						</div>
					</div>

					<div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
						<div>
							<h2 className="text-base font-semibold leading-7 text-gray-900">
								Patient Documents
							</h2>
							<p className="mt-1 text-sm leading-6 text-gray-600">
								We'll always let you know about important changes, but you pick
								what else you want to hear about.
							</p>
						</div>

						<div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6 md:col-span-2">
							<div className="sm:col-span-4">
								<label
									htmlFor="email"
									className="block text-sm font-medium leading-6 text-gray-900">
									Document
								</label>
								<div className="mt-2">
									<div className="col-span-12">
										<div
											className={`flex justify-center items-center h-48 border-2 border-dashed rounded-lg p-5 ${
												isDragActive
													? "bg-sky-50 border-sky-400"
													: "border-gray-300"
											}`}
											onDragEnter={handleDragEnter}
											onDragLeave={handleDragLeave}
											onDragOver={(e) => e.preventDefault()}
											onDrop={handleDrop}>
											<input
												placeholder="fileInput"
												className="hidden"
												type="file"
												multiple
												ref={inputRef}
												onChange={handleSelectUpload}
											/>

											{file ? (
												<p className={`text-sm text-sky-800`}>{file.name}</p>
											) : (
												<p className="text-sm text-gray-400">
													Drag & Drop files or{" "}
													<span
														className=" text-blue-400 n cursor-pointer"
														onClick={openFileExplorer}>
														<span>Click to select file</span>
													</span>{" "}
													to upload
												</p>
											)}
										</div>
									</div>
									{progress > 0 && <div>Uploading files {progress} %</div>}
								</div>
							</div>

							{/* Table */}

							<div className="sm:col-span-6 mt-8 flow-root w-100">
								<div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
									<div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
										<table className="min-w-full divide-y divide-gray-300">
											{documents.length > 0 && (
												<thead>
													<tr>
														<th
															scope="col"
															className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
															Document Name
														</th>
														<th
															scope="col"
															className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
															File Description
														</th>
														<th
															scope="col"
															className="relative py-3.5 pl-3 pr-4 sm:pr-0">
															<span className="sr-only">Remove</span>
														</th>
													</tr>
												</thead>
											)}
											<tbody className="divide-y divide-gray-200">
												{documents &&
													documents.map((document) => (
														<>
															<tr key={document.key}>
																<td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
																	<input
																		type="text"
																		name="document-title"
																		id="document-title"
																		defaultValue={document.name}
																		placeholder={document.name}
																		onChange={(e) =>
																			updateDocument(e, document)
																		}
																		className="px-2 block w-full rounded-md border-0 py-0.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
																	/>
																</td>
																<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
																	{document.originalname}
																</td>
																<td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
																	<button
																		onClick={(e) => {
																			handleRemoveDocuments(document.key);
																			handleDocumentDelete(e, document.key);
																		}}
																		className="text-indigo-600 hover:text-indigo-900">
																		Remove
																		<span className="sr-only">
																			, {document.key}
																		</span>
																	</button>
																</td>
															</tr>
														</>
													))}
											</tbody>
										</table>
										{documents.length > 0 && (
											<p className="text-sm text-right my-2">
												Files Uploaded: {documents.length}
											</p>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
						<div>
							<h2 className="text-base font-semibold leading-7 text-gray-900">
								Questions
							</h2>
							<p className="mt-1 text-sm leading-6 text-gray-600">
								Leave your questions for doctors (max length 250)
							</p>
						</div>

						<div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6 md:col-span-2">
							<div className="sm:col-span-6">
								<label
									htmlFor="email"
									className="block text-sm font-medium leading-6 text-gray-900">
									Type your question here
								</label>
								<div className="mt-2">
									<textarea
										rows={4}
										maxLength={250}
										name="comment"
										id="comment"
										autoComplete="given-name"
										onChange={(e) => setComment(e.target.value)}
										value={comment}
										className="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
									/>
								</div>
							</div>
							<button
								type="button"
								onClick={handleComments}
								className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
								Add
							</button>
							<div className="sm:col-span-6 mt-8 flow-root w-100">
								<div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
									<div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
										<table className="min-w-full divide-y divide-gray-300">
											<thead>
												<tr>
													<th
														scope="col"
														className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
														{comments.length > 0 && "Comment"}
													</th>
													<th
														scope="col"
														className="relative py-3.5 pl-3 pr-4 sm:pr-0">
														<span className="sr-only">Remove</span>
													</th>
												</tr>
											</thead>
											<tbody className="divide-y divide-gray-200">
												{comments &&
													comments.map((comment) => (
														<tr key={comment.id}>
															<td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
																{comment.description}
															</td>
															<td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
																<button
																	onClick={() =>
																		handleRemoveComments(comment.id)
																	}
																	className="text-indigo-600 hover:text-indigo-900">
																	Remove
																	<span className="sr-only">
																		, {comment.id}
																	</span>
																</button>
															</td>
														</tr>
													))}
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
						<div>
							<h2 className="text-base font-semibold leading-7 text-gray-900">
								Package Information
							</h2>
							<p className="mt-1 text-sm leading-6 text-gray-600">
								Exisiting package
							</p>
						</div>

						<div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
							<div className="sm:col-span-8">
								<Price />
							</div>
						</div>
					</div>
				</div>

				<div className="mt-6 flex items-center justify-end gap-x-6">
					<button
						disabled={loading}
						type={!loading && "submit"}
						className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
						{loading ? "Loading..." : "Submit"}
					</button>
				</div>
			</form>
		</>
	);
}
