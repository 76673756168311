import { CheckIcon } from "@heroicons/react/20/solid";

export default function Price() {
	return (
		<div className="bg-white">
			<div>
				<div className="rounded-md ring-1 ring-gray-200 lg:mx-0 lg:flex lg:max-w-none">
					<div className="p-8 sm:p-10 lg:flex-auto">
						<h3 className="text-2xl font-bold tracking-tight text-gray-900">
							Basic membership
						</h3>
						<p className="mt-6 text-base leading-7 text-gray-600">
							Lorem ipsum dolor sit amet consect etur adipisicing elit. Itaque
							amet indis perferendis blanditiis repellendus etur quidem
							assumenda.
						</p>
						<div className="mt-10 flex items-center gap-x-4">
							<h4 className="flex-none text-sm font-semibold leading-6 text-indigo-600">
								What’s included
							</h4>
							<div className="h-px flex-auto bg-gray-100" />
						</div>
						<ul
							role="list"
							className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6">
							<li className="flex gap-x-3">
								<CheckIcon
									className="h-6 w-5 flex-none text-indigo-600"
									aria-hidden="true"
								/>
								{"Lorem Ipsum Dolor"}
							</li>
							<li className="flex gap-x-3">
								<CheckIcon
									className="h-6 w-5 flex-none text-indigo-600"
									aria-hidden="true"
								/>
								{"Lorem Ipsum Dolor"}
							</li>
							<li className="flex gap-x-3">
								<CheckIcon
									className="h-6 w-5 flex-none text-indigo-600"
									aria-hidden="true"
								/>
								{"Lorem Ipsum Dolor"}
							</li>
							<li className="flex gap-x-3">
								<CheckIcon
									className="h-6 w-5 flex-none text-indigo-600"
									aria-hidden="true"
								/>
								{"Lorem Ipsum Dolor"}
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	);
}
