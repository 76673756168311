import React from "react";

function Home() {
	return (
		<div>
			<h1>Welcome to the Home Page!</h1>
			<p>This is some content specific to the Home Page.</p>
		</div>
	);
}

export default Home;
