import { Disclosure } from "@headlessui/react";
import { MinusSmallIcon, PlusSmallIcon } from "@heroicons/react/24/outline";

export default function Accordion({
	opinions,
	questions,
	handleOpinion,
	handleSubmit,
}) {
	return (
		<div className="bg-white">
			<div className="mx-auto max-w-7xl">
				<div className="mx-auto max-w-4xl divide-y divide-gray-900/10">
					<h2 className="text-3md font-bold leading-10 tracking-tight text-gray-900">
						Answer patient questions
					</h2>
					<dl className="mt-5 space-y-6 divide-y divide-gray-900/10">
						{opinions.map((opinion) => (
							<Disclosure as="div" key={opinion.question_id} className="pt-6">
								{({ open }) => (
									<>
										<dt>
											<Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
												<span className="text-base font-semibold leading-7">
													{opinion.question_description}
												</span>
												<span className="ml-6 flex h-7 items-center">
													{open ? (
														<MinusSmallIcon
															className="h-6 w-6"
															aria-hidden="true"
														/>
													) : (
														<PlusSmallIcon
															className="h-6 w-6"
															aria-hidden="true"
														/>
													)}
												</span>
											</Disclosure.Button>
										</dt>
										<Disclosure.Panel as="dd" className="mt-2">
											{opinion.opinion !== null ? (
												<div className="sm:col-span-12">
													<div className="mt-2">
														<textarea
															value={opinion.opinion}
															rows={4}
															maxLength={250}
															name="comment"
															id="comment"
															autoComplete="given-name"
															className="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
														/>
													</div>
												</div>
											) : (
												<>
													<div className="sm:col-span-12">
														<div className="mt-2">
															<textarea
																onChange={(e) => handleOpinion(e, opinion)}
																rows={4}
																maxLength={250}
																name="comment"
																id="comment"
																autoComplete="given-name"
																className="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
															/>
														</div>
													</div>
													<button
														onClick={() => handleSubmit(opinion.question_id)}
														type="button"
														className="mt-3 mb-3 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
														Save
													</button>{" "}
												</>
											)}
										</Disclosure.Panel>
									</>
								)}
							</Disclosure>
						))}
					</dl>
				</div>
			</div>
		</div>
	);
}
