import React, { useEffect, useRef, useState } from "react";
import * as cornerstone from "cornerstone-core";
import cornerstoneTools, { ZoomTool, LengthTool } from "cornerstone-tools";
import Hammer from "hammerjs/hammer.js";
import cornerstoneMath from "cornerstone-math";
import cornerstoneWebImageLoader from "cornerstone-web-image-loader";
import * as cornerstoneWADOImageLoader from "cornerstone-wado-image-loader";
import * as dicomParser from "dicom-parser/dist/dicomParser";
import { useSearchParams } from "react-router-dom";

function DicomViewer() {
	const [loadTool, setLoadTool] = useState(null);
	const canvasRef = useRef(null);
	const [imageId, setImageId] = useState("");
	const [searchParams] = useSearchParams();
	const query = searchParams.get("url");

	useEffect(() => {
		console.log("searchParams", query);
		cornerstoneTools.external.cornerstone = cornerstone; // set up Cornerstone Tools
		cornerstoneTools.external.cornerstoneMath = cornerstoneMath;
		cornerstoneWebImageLoader.external.cornerstone = cornerstone; // set up Cornerstone Web Image Loader
		cornerstoneTools.external.Hammer = Hammer;

		const element = canvasRef.current;

		cornerstone.loadAndCacheImage(query).then((image) => {
			console.log("image", image);
			cornerstone.enable(element); // enable the canvas with Cornerstone
			var viewport = cornerstone.getDefaultViewportForImage(element, image);
			element.style.width = viewport.windowWidth;
			element.style.height = "500px";
			console.log("viewport", viewport);
			cornerstone.displayImage(element, image, viewport);
			setLoadTool(true); // enable
		});
	}, []);

	useEffect(() => {
		const element = canvasRef.current;

		cornerstoneTools.init();
		cornerstoneTools.addToolForElement(element, ZoomTool);
		cornerstoneTools.addToolForElement(element, LengthTool);
		cornerstoneTools.setToolActive("Zoom", {
			mouseButtonMask: 2,
			minScale: 0.25,
			maxScale: 20.0,
			preventZoomOutsideImage: true,
		}); // activate ZoomTool with left mouse button

		cornerstoneTools.setToolActive("Length", {
			mouseButtonMask: 1,
		});
	}, [loadTool]);

	return <div className="viewer h-max" ref={canvasRef}></div>;
}

export default DicomViewer;
